import dayjs, { ConfigType } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import { LocaleDateVariant } from '../types';

dayjs.extend(utc);
dayjs.extend(advancedFormat);

/**
 * Helper that formats a date according to predefined variants.
 */
export const formatLocaleDate = (
    date: number,
    locale?: Intl.LocalesArgument | undefined,
    variant?: LocaleDateVariant
) => {
    switch (variant) {
        case LocaleDateVariant.day:
            return new Date(date)
                .toLocaleDateString(locale, {
                    timeZone: 'UTC',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                })
                .replace(/,/g, '');
        case LocaleDateVariant.quarterly:
            return dayjs.utc(date).format('[Q]Q YYYY');
        // Defaults to shortMonthYear
        case LocaleDateVariant.shortMonthYear:
        default:
            return new Date(date).toLocaleDateString(locale, { timeZone: 'UTC', year: 'numeric', month: 'short' });
    }
};

/**
 * Determines if times are equivalent.
 * @example
 * isSameTime(1667333000000, '2022-11-01T20:03:20Z');
 * // -> true
 */
export const isSameTime = (time1: ConfigType, time2: ConfigType) => {
    return dayjs.utc(time1).isSame(dayjs.utc(time2));
};
